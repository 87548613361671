<template>
  <b-card>
    <div class="row">
      <div class="col-12">
        <hwa-text-area
          v-model="footerContent.hwa"
          label="HWA"
        />
      </div>
      <div class="col-12 mt-3">
        <strong>Labels</strong>
      </div>
      <div class="col-4 mt-2">
        <div class="row">
          <div class="col-12">
            <strong>Lean</strong>
          </div>
        </div>
        <div class="row">
          <draggable
            v-model="footerContent.lean"
            class="cursor-move full-width"
          >
            <div
              v-for="(item,index) in footerContent.lean"
              :key="index"
              class="col-12 mt-2"
            >
              <div class="row align-items-center">
                <div class="col-8">
                  <hwa-input
                          v-model="item.label"
                          :label="item.label"
                  />
                </div>
                <div class="col-4">
                  <b-form-checkbox
                          v-model="item.visible"
                          class="custom-control-primary"
                  >
                    Show
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </draggable>
          <!--          <div class="col-12">-->
          <!--            <hwa-button-->
          <!--              class="float-right"-->
          <!--              :icon-only="true"-->
          <!--              icon="PlusIcon"-->
          <!--              @onClick="addItem('lean')"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>
      <div class="col-4 mt-2">
        <div class="row">
          <div class="col-12">
            <strong>Company</strong>
          </div>
        </div>
        <div class="row">
          <draggable
            v-model="footerContent.company"
            class="cursor-move full-width"
          >
            <div
              v-for="(item,index) in footerContent.company"
              :key="index"
              class="col-12 mt-2"
            >
              <div class="row align-items-center">
                <div class="col-8">
                  <hwa-input
                          v-model="item.label"
                          :label="item.label"
                  />
                </div>
                <div class="col-4">
                  <b-form-checkbox
                          v-model="item.visible"
                          class="custom-control-primary"
                  >
                    Show
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </draggable>
          <!--          <div class="col-12">-->
          <!--            <hwa-button-->
          <!--              class="float-right"-->
          <!--              :icon-only="true"-->
          <!--              icon="PlusIcon"-->
          <!--              @onClick="addItem('company')"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>
      <div class="col-4 mt-2">
        <div class="row">
          <div class="col-12">
            <strong>Legal</strong>
          </div>
        </div>
        <div class="row">
          <draggable
            v-model="footerContent.legal"
            class="cursor-move full-width"
          >
            <div
              v-for="(item,index) in footerContent.legal"
              :key="index"
              class="col-12 mt-2"
            >
              <div class="row align-items-center">
                <div class="col-8">
                  <hwa-input
                          v-model="item.label"
                          :label="item.label"
                  />
                </div>
                <div class="col-4">
                  <b-form-checkbox
                          v-model="item.visible"
                          class="custom-control-primary"
                  >
                    Show
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </draggable>
          <!--          <div class="col-12">-->
          <!--            <hwa-button-->
          <!--              class="float-right"-->
          <!--              :icon-only="true"-->
          <!--              icon="PlusIcon"-->
          <!--              @onClick="addItem('legal')"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>

      <!--      <div class="col-12 mt-3">-->
      <!--        <strong>Links</strong>-->
      <!--      </div>-->
      <!--      <div class="col-4 mt-2">-->
      <!--        <div class="row">-->
      <!--          <div class="col-12">-->
      <!--            <strong>Lean</strong>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row">-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.lean.faqs.url"-->
      <!--                    label="FAQs"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.lean.learn.url"-->
      <!--                    label="Learn"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.lean.glossary.url"-->
      <!--                    label="Glossary"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-4 mt-2">-->
      <!--        <div class="row">-->
      <!--          <div class="col-12">-->
      <!--            <strong>Company</strong>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row">-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.company.about_us.url"-->
      <!--                    label="About Us"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.company.media.url"-->
      <!--                    label="Media"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.company.careers.url"-->
      <!--                    label="Careers"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.company.partner_with_us.url"-->
      <!--                    label="Partner With Us"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-4 mt-2">-->
      <!--        <div class="row">-->
      <!--          <div class="col-12">-->
      <!--            <strong>Legal</strong>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row">-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.legal.privacy_policy.url"-->
      <!--                    label="Privacy Policy"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.legal.terms_of_use.url"-->
      <!--                    label="Terms of Use"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.legal.disclosures_licensing.url"-->
      <!--                    label="Disclosures and Licensing"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.legal.affiliated_business.url"-->
      <!--                    label="Affiliated Business"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col-12">-->
      <!--            <hwa-input-->
      <!--                    v-model="footerContent.legal.browser_disclaimer.url"-->
      <!--                    label="Browser Disclaimer"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="row">
      <div class="col-12">
        <hwa-button
          :loading="saving"
          label="Save"
          @onClick="saveFooterContent()"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BFormCheckbox } from 'bootstrap-vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import draggable from 'vuedraggable'

export default {
  name: 'Settings',
  components: {
    HwaButton,
    HwaInput,
    HwaTextArea,
    BCard,
    draggable,
    BFormCheckbox,
  },
  mixins: [showToast],
  data() {
    return {
      footerContent: {},
      saving: false,
    }
  },
  computed: {
    content() {
      return this.$store.getters['auth/footerContent']
    },
  },
  watch: {
    content(val) {
      this.footerContent = val
    },
  },
  mounted() {
    this.footerContent = this.content
    this.getFooterContent()
  },
  methods: {
    getFooterContent() {
      this.$http.get('api/footer-content')
        .then(res => {
          this.$store.commit('auth/setFooterContent', res.data)
          this.loaded = true
        })
    },
    addItem(category) {
      this.footerContent[category].push({ label: 'Item', url: 'item', order: this.footerContent[category].length })
    },
    saveFooterContent() {
      this.saving = true
      this.$http.put('/api/update-footer-content', { footer: this.footerContent })
        .then(res => {
          this.saving = false
          this.showSuccess('Success')
        })
        .catch(err => {
          this.saving = false
          this.showError('Failed to update faq')
        })
    },

  },
}
</script>

<style scoped>

</style>
